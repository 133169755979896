(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW
    .on('resize', function() {
      window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();
    })
    .trigger('resize');

  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  }
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  }


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask({
    regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || $elemToScroll.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this);

      if( !$lnk.hasClass('active') ) {
        var $destination = $($lnk.parents('.js-tabs').data('tabDest'));

        $lnk.addClass('active').parent().siblings().children('a').removeClass('active');
        $destination.children().addClass('dn').eq($lnk.parent().index()).removeClass('dn');
      }
    })

    // Init spinner
    // ------------
    .on('initSpinner basketListUpdated', function() {
      $('.js-spinner-input')
        .each(function() {
          var $spinner = $(this),
              min = $spinner.data('min') || 1,
              max = $spinner.data('max') || null,
              step = $spinner.data('step') || 1;

          $spinner
            .val(function(index, value) {
              if( value < min )
                return min;

              return value;
            })
            .spinner({
              culture: 'en',
              min: min,
              max: max,
              page: 0,
              step: step,

              change: function(e, ui) {
                e.preventDefault();

                var _min = $(e.target).data('min') || 1;

                if( $(e.target).val() < _min )
                  $(e.target).val(_min).trigger('change');
              },
              spin: function(e, ui) {
                e.preventDefault();

                $(e.target).val(ui.value).trigger('change');
              }
            });
        })
        .off('cut copy paste')
        .on('cut copy paste', function(e) {
          e.preventDefault();
        });
    })

    // Stick elements
    // --------------
    .on('initStickBasketRightPanel basketListUpdated', function() {
      $(".basket-right-panel > div").stick_in_parent();
    })

    // Social window open
    // ------------------
    /*.on('click.socialWindowOpen', '.footer-social-icons a, .contact-social-icons a', function(e) {
      e.preventDefault();

      var socialWin;

      if( socialWin = window.open($(this).prop('href'), '_blank', 'chrome=1,centerscreen=1,dialog=1,modal=0,close=1,directories=0,titlebar=1,toolbar=1,location=1,status=1,menubar=0,scrollbars=1,resizable=1,top=120,left=30,width=1040,height=600')) {
        socialWin.focus();
      }
    })*/

    // Show header city chooser
    // ------------------------
    .on('click.showHeaderCityChooser', '.header-city-label', function(e) {
      e.preventDefault();

      $('.header-city-choice-container').toggleClass('is-opened');
    })

    // Hide header city chooser
    // ------------------------
    .on('click.hideHeaderCityChooser', function(e) {
      if( !($(e.target).closest('.header-city-label').length || $(e.target).closest('.header-cities-container').length) ) {
        $('.header-city-choice-container').removeClass('is-opened');

        e.stopPropagation();
      }
    })

    // Select header city
    // ------------------
    .on('click.selectHeaderCity', '.header-cities-container > div', function(e) {
      e.preventDefault();

      var $self = $(this);

      if( !$self.hasClass('active') ) {
        $self.addClass('active').siblings().removeClass('active');
        $('.header-city-label').text($self.data('city'));
        $('.header-phone a').text($self.data('phone')).prop('href', $self.data('phoneClear'));
      }

      $('.header-city-choice-container').removeClass('is-opened');
    })

    // Faq list
    // --------
    .on('click.faqList', '.faq-list > div', function(e) {
      e.preventDefault();

      $(this).toggleClass('active').children('div:last-child').slideToggle(300);
    })

    // Callback links
    // --------------
    .on('click.headerCallbackLink', '.header-callback-link > a', function() {
      CALLBACKS.phoneCallbackHeaderLink();
    })
    .on('click.footerCallbackLink', '.footer-callback-link > a', function() {
      CALLBACKS.phoneCallbackFooterLink();
    })

    .trigger('initSpinner')
    .trigger('initStickBasketRightPanel');



  if( WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH ) {
    // Header menu button
    // ------------------
    $MENU_CHECKBOX
      .on('change', function() {
        if( this.checked ) {
          $BODY.addClass('menu-is-opened');
        } else {
          $BODY.removeClass('menu-is-opened');
        }
      })
      .prop('checked', false)
      .prop('disabled', false)
      .trigger('change');

    $('.header-menu').children('.product-menu.with-dropdown').addClass('is-opened');

    $DOCUMENT
      // Close header menu
      // -----------------
      .on('click.closeHeaderMenu', function(e) {
        if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu').length) ) {
          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          e.stopPropagation();
        }
      })

      // Header menu items
      .on('click.headerMenu', '.header-menu > li > div:first-child > div > a, .header-menu > li > div:first-child > div > span, .header-menu > li > div:first-child > .dropmarker', function(e) {
        e.preventDefault();

        var $self = $(this),
            $parent = $self.closest('li'),
            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-opened');

        $parent.siblings('.with-dropdown').removeClass('is-opened');

        if( isWithDropdown ) {
          if( isOnHover ) {
            if( $self.prop("tagName").toUpperCase() === 'A' )
              location.href = $self.prop('href');
            else
              $parent.removeClass('is-opened');
          } else {
            $parent.addClass('is-opened');
          }
        } else {
          if( $self.prop("tagName").toUpperCase() === 'A' )
            location.href = $self.prop('href');
        }
      });
  }


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    infobar: false,
    smallBtn: false,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    protect: true,
    transitionEffect: 'slide',
    touch: false,
    hash: false,
    mobile: {
      //autoFocus: false,
      touch: {
        vertical: true,
        momentum: true
      }
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    }
  });

  $.fancyBoxConfirm = function(opts) {
    opts = $.extend(true, {
      title: 'Вы уверены?',
      message: '',
      buttons: {
        yes: {
          label: 'Да',
          cssClass: 'red'
        },
        no: {
          label: 'Нет',
          cssClass: ''
        }
      },
      callbacks: {
        yes: $.noop,
        no: $.noop
      }
    }, opts || {});

    var content = '<div class="form-popup-container fancybox-confirm">' +
      '<div class="form-block-container">' +
        '<div class="subsubheader">' + opts.title + '</div>' +
        opts.message +
        '<div class="form">' +
          '<div class="form-buttons">';

    $.each(opts.buttons, function(key, button) {
      if( button !== false )
        content = content + '<div class="form-button">' +
            '<button class="button-style ' + button.cssClass + '" data-fancybox-close data-value="' + key + '"><span>' + button.label + '</span></button>' +
          '</div>';
    });

    content = content + '</div>' +
          '</div>' +
        '</div>' +
      '</div>';

    $.fancybox.open({
      type: 'html',
      src: content,
      opts: {
        modal: true,
        baseTpl:
          '<div class="fancybox-container" role="dialog" tabindex="-1">' +
          '<div class="fancybox-bg"></div>' +
          '<div class="fancybox-inner">' +
          '<div class="fancybox-stage"></div>' +
          '</div>' +
          '</div>',
        afterClose: function(widget, currentSlide, e) {
          var button = e ? $(e.target).closest('button') || $(e.currentTarget).closest('button') : null,
              value = button ? $(button).data('value') : 'no';

          opts.callbacks[value](widget, currentSlide);
        }
      }
    });
  };


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: true,
    autoplaySpeed: 10000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    pauseOnDotsHover: true,
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

    // Main rotator
    $('.main-rotator-container .main-rotator-wrapper').slick($.extend({}, slickSettings, {
      centerMode: true,
      centerPadding: '0px',
      fade: true,
      arrows: false,
      dots: true,
      slide: '.main-rotator-slide',
      appendDots: '.main-rotator-container > .main-rotator-dots',
      draggable: true
    }));

    // Bottom rotator
    $('.bottom-rotator-container .bottom-rotator-wrapper').slick($.extend({}, slickSettings, {
      centerMode: true,
      centerPadding: '0px',
      fade: true,
      arrows: false,
      dots: true,
      slide: '.bottom-rotator-slide',
      appendDots: '.bottom-rotator-container > .bottom-rotator-dots',
      draggable: true
    }));
})();
